import cn from 'classnames'
import React from 'react'

//
import BackgroundImage from '../../../../static/images/pattern-grey.jpg'
import { ButtonGroup, ButtonNew, SectionHeader } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { ImageFluid } from '../../../utils/image'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'
import { MediaComponent } from '../crissCross/mediaComponent'

const SECTION_HEADER_BACKGROUND = {
  GRAY: 'gray',
  BLACK: 'black',
  WHITE: 'white',
  PATTERN: 'pattern'
}

const BlockSectionHeader = ({
  data: {
    icon,
    preface,
    title,
    headingType,
    shortDescription,
    primaryAction,
    secondaryAction,
    contentAlignment,
    backgroundColor,
    image,
    video,
    logo
  }
}) => {
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  return (
    <section
      className={cn('l-section u-position-relative', {
        'l-section--grey': backgroundColor === SECTION_HEADER_BACKGROUND.GRAY,
        'l-section--black': backgroundColor === SECTION_HEADER_BACKGROUND.BLACK
      })}
    >
      {
        // Set background image is Homepage is true
        backgroundColor === SECTION_HEADER_BACKGROUND.PATTERN ? (
          <div className={'c-section-header__homepage-bg u-ignore-block-gap-margin'}>
            <ImageFluid
              image={{
                url: BackgroundImage
              }}
            />
          </div>
        ) : null
      }

      <div
        className={cn('l-container--default-spacing', {
          'l-container:12/12': contentAlignment === 'left',
          'l-container:10/12': !contentAlignment || contentAlignment === 'center'
        })}
      >
        <SectionHeader
          align={contentAlignment || 'center'}
          eyebrow={preface || ''}
          title={title || ''}
          headingType={headingType}
          icon={icon || ''}
          text={shortDescription || ''}
          inverted={backgroundColor === SECTION_HEADER_BACKGROUND.BLACK}
          image={!video && image ? image : null}
          logo={logo}
        >
          {(primaryAction || secondaryAction) && (
            <ButtonGroup center>
              {primaryAction && (
                <ButtonNew
                  url={getUrl(primaryAction, slugPrefix)}
                  subtext={primaryAction.subtext}
                  variant={BUTTON_VARIANT.GREEN}
                >
                  {primaryAction.title || ''}
                </ButtonNew>
              )}
              {secondaryAction && (
                <ButtonNew
                  variant={BUTTON_VARIANT.SECONDARY}
                  url={getUrl(secondaryAction, slugPrefix)}
                  subtext={secondaryAction.subtext}
                >
                  {secondaryAction.title || ''}
                </ButtonNew>
              )}
            </ButtonGroup>
          )}
        </SectionHeader>
      </div>
      {video ? (
        <div className="l-container--default-spacing l-container:12/12">
          <MediaComponent {...{ image, wistiaVideo: video }} height={255} width={400} />
        </div>
      ) : null}
    </section>
  )
}

export default BlockSectionHeader
