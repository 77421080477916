import cn from 'classnames'
import { gsap } from 'gsap'
import React, { useEffect, useRef, useState, memo } from 'react'

//
import { IconNew } from '..'
import { SiteDataContext } from '../../components'

const ExpansionPanel = ({
  label = '',
  children,
  classes,
  iconFilled = false,
  withBorder = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentEl = useRef(null)
  const tween = useRef(null)
  const { isTextRtl } = React.useContext(SiteDataContext)

  const propsFAQQuestion = { itemProp: 'name' }

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!contentEl.current) {
      return
    }

    tween.current = gsap.to(contentEl.current, {
      height: isOpen ? 'auto' : 0,
      opacity: isOpen ? 1 : 0,
      duration: 0.25,
      ease: 'power2.out'
    })

    return () => {
      tween.current.kill()
    }
  }, [isOpen])

  return (
    <button
      type="button"
      className={cn(
        'c-expansion-panel',
        {
          'c-expansion-panel--with-border': withBorder,
          'c-expansion-panel--rtl': isTextRtl
        },
        classes
      )}
      onClick={toggleIsOpen}
      {...rest}
    >
      <div
        className={cn('c-expansion-panel__trigger', {
          'is-open': isOpen
        })}
      >
        <span
          className="c-expansion-panel__trigger-content"
          {...(rest.itemProp === 'mainEntity' && propsFAQQuestion)}
        >
          {label}

          <IconNew
            name={isOpen ? 'tabler-arrow-narrow-up' : 'tabler-arrow-narrow-down'}
            classes={`c-expansion-panel__icon ${
              iconFilled ? 'c-expansion-panel__icon--filled' : ''
            } ${isOpen ? 'open' : ''}`}
          />
        </span>
      </div>

      {children && (
        <div ref={contentEl} aria-hidden={!isOpen} className="c-expansion-panel__content">
          <div className="c-expansion-panel__inner-content">{children}</div>
        </div>
      )}
    </button>
  )
}

export default memo(ExpansionPanel)
