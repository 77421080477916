import cn from 'classnames'
import React from 'react'

import { SiteDataContext } from '../../components'
import { BILLING_TIME_ANNUALLY } from '../../components/blocks/pricingCards/pricingCards'
import RichText from '../../components/rich-text'
import { ImageFluid } from '../../utils/image'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import { ButtonNew, IconNew } from '../index'

const PricingCard = ({ card, billingTime, microCopy, slugPrefix, showMore, toggleShowMore }) => {
  const { market, isTextRtl } = React.useContext(SiteDataContext)
  const bulletPoints = Array.isArray(card?.bulletPoints) ? card.bulletPoints : []

  const handleShowMore = () => {
    toggleShowMore(!showMore)
  }

  return (
    <article
      className={cn('c-pricing-card', {
        'c-pricing-card--dark': card.theme === 'dark',
        'c-pricing-card--rlt': isTextRtl
      })}
    >
      <div className="c-pricing-card__price-container">
        <div className="c-pricing-card__title-container">
          {card.image ? (
            <ImageFluid
              image={{
                url: card.image.file.url
              }}
              alt={card.image.title || card.title}
              width={32}
              height={32}
              containImages
            />
          ) : card.icon ? (
            <IconNew icon={card.icon} width={32} height={32} />
          ) : null}
          <h3 className="c-pricing-card__header">{card.title}</h3>
        </div>
        {card.description && (
          <p className="c-pricing-card__description">
            <RichText data={card.description} />
          </p>
        )}
        {card.displayTitle ? (
          <p className="c-pricing-card__display-title">{card.displayTitle}</p>
        ) : card.price ? (
          <>
            <p className="c-pricing-card__price">
              {getMicroCopy({ key: 'pricing.from', fallback: 'From', data: microCopy })}{' '}
              <span className="c-pricing-card__price-text">
                {market?.currencySymbol ? market.currencySymbol : '$'}
                {card.price}
              </span>
              <span className="c-pricing-card__billing-time">/</span>
              <span className="c-pricing-card__billing-time">
                {getMicroCopy({ key: 'pricing.month', data: microCopy })}
              </span>
            </p>
          </>
        ) : null}

        {card.action && (
          <ButtonNew
            size={'md'}
            fullWidth
            classes="c-pricing-card__action"
            variant={card.theme === 'dark' ? BUTTON_VARIANT.GREEN : BUTTON_VARIANT.PRIMARY}
            url={getUrl(card.action, slugPrefix)}
          >
            {card.action.title || ''}
          </ButtonNew>
        )}

        {card.totalAnnualPrice && (
          <p
            className={cn('c-pricing-card__annual-footnote', {
              'c-pricing-card__annual-footnote--visible': billingTime === BILLING_TIME_ANNUALLY
            })}
          >
            {market?.currencySymbol ? market.currencySymbol : '$'}
            {card?.totalAnnualPrice}{' '}
            {getMicroCopy({ key: 'pricing.billedAnnually', data: microCopy })}
          </p>
        )}
      </div>

      <hr />

      {bulletPoints.length > 0 ? (
        <div className={'c-pricing-card__bullet-points l-grid__col:12/12'}>
          <div className="c-pricing-card__bullet-point c-pricing-card__bullet-point--label">
            <div className="c-pricing-card__bullet-point__text">
              <p className="c-pricing-card__bullet-point__title">
                {getMicroCopy({ key: 'pricing.includes', data: microCopy })}:
              </p>
            </div>
          </div>
          {bulletPoints.slice(0, showMore ? bulletPoints.length : 4).map((bulletPoint) => (
            <div key={bulletPoint.id} className="c-pricing-card__bullet-point">
              {bulletPoint.icon ? <IconNew name={bulletPoint.icon} /> : null}
              <div className="c-pricing-card__bullet-point__text">
                <p className="c-pricing-card__bullet-point__title">{bulletPoint.title}</p>
              </div>
            </div>
          ))}
          {bulletPoints.length > 4 ? (
            <div className="c-pricing-card__show-toggle">
              <ButtonNew
                variant={BUTTON_VARIANT.TERTIARY}
                onClick={handleShowMore}
                classes={'u-padding-0'}
              >
                {showMore
                  ? getMicroCopy({ key: 'integration.showLess', data: microCopy })
                  : getMicroCopy({ key: 'integration.showMore', data: microCopy })}

                <IconNew name={showMore ? 'tabler-chevron-up' : 'tabler-chevron-down'} />
              </ButtonNew>
            </div>
          ) : null}
        </div>
      ) : null}
    </article>
  )
}

export default PricingCard
