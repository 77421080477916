import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { useContentfulImage } from '../storybook/hooks/use-contentful-image'

export const ImageFluid = ({
  image,
  alt = '',
  loading = 'lazy',
  className,
  containImages,
  aspectRatio = 1.5,
  width = 1920,
  height = 800,
  ...rest
}) => {
  if (image?.fluid) {
    const { fluid } = image

    if (!fluid) {
      return null
    }

    return (
      <GatsbyImage
        image={fluid}
        alt={alt}
        loading={loading}
        className={className}
        objectFit={containImages ? 'contain' : 'cover'}
        {...rest}
      />
    )
  }

  if (image?.url || image?.file?.url) {
    const url = image?.url || image?.file?.url
    const dynamicImage = useContentfulImage({
      image: {
        url: url,
        width,
        height,
        aspectRatio,
        quality: 80
      }
    })
    return (
      <GatsbyImage
        image={dynamicImage}
        alt={alt}
        loading={loading}
        className={className}
        objectFit={containImages ? 'contain' : 'cover'}
        {...rest}
      />
    )
  }

  return null
}

export const ImageFixed = ({ image, alt = '', loading = 'lazy', className, ...rest }) => {
  if (!image?.fixed) {
    return null
  }

  const { fixed } = image

  if (!fixed) {
    return null
  }

  return (
    <GatsbyImage
      image={fixed}
      alt={alt}
      objectFit="contain"
      loading={loading}
      className={className}
      {...rest}
    />
  )
}

export const ImageFluidPreview = ({
  url,
  aspectRatio = 21 / 9,
  alt = '',
  loading = 'lazy',
  className,
  containImages,
  width = 1920,
  height = 800,
  ...rest
}) => {
  if (!url) {
    return null
  }

  const dynamicImage = useContentfulImage({
    image: {
      url,
      width,
      height,
      aspectRatio,
      quality: 80
    }
  })

  const image = {
    fluid: dynamicImage
  }

  return <ImageFluid image={image} alt={alt} loading={loading} className={className} {...rest} />
}

export const ImageFixedPreview = ({
  url,
  width,
  height,
  fit = 'fill',
  alt = '',
  loading = 'lazy',
  className,
  ...rest
}) => {
  if (!url) {
    return null
  }

  const dynamicImage = useContentfulImage({
    image: {
      url,
      width,
      height,
      quality: 80
    }
  })

  const image = {
    fixed: dynamicImage
  }

  return (
    <ImageFixed
      style={{ width: `${width}px`, height: `${height}px` }}
      image={image}
      alt={alt}
      loading={loading}
      className={className}
      {...rest}
    />
  )
}
