import cn from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'

import { MicroCopyContext, SiteDataContext } from '../../components/page-wrapper'
import { graphqlFetch } from '../../utils/graphql-fetch'
import { getMicroCopy } from '../../utils/microcopy'
import { getUrl } from '../../utils/urls'
import Icon from '../IconNew/Icon'
import Loader from '../Loader'
import NotificationBar from '../NotificationBar'
import SideDrawer from '../SideDrawer'

const notificationsQuery = `
  query($locale: String!) {
    notificationsCollection(locale: $locale) {
      items {
        externalUrl
        notificationMessage
        relativePath
        enabledMarketsCollection {
          items {
            countryIsoCode
          }
        }
        enabledLocales
        accessibleText
        content: contentCollection(limit: 1, locale: $locale) {
          items {
            ... on Customer {
              customerSlug: slug(locale: $locale)
            }
            ... on Page {
              slug(locale: $locale)
            }
            ... on Article {
              blogSlug: slug(locale: $locale)
            }
            ... on Integration {
              integrationSlug: slug(locale: $locale)
            }
            ... on WistiaVideo {
              __typename
              videoId
            }
          }
        }
      }
    }
  }
`

const isEnabled = (notification, currentISOCode) => {
  return (
    notification?.enabledMarketsCollection?.items.filter(
      (market) => market.countryIsoCode === currentISOCode
    ).length > 0 && notification.enabledLocales
  )
}

export default function TopBar({
  headerAction,
  slugPrefix = '',
  langSlugs = {},
  marketsSlugs = {},
  allLangSlugs,
  storybookNotifications = []
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [notifications, setNotifications] = React.useState(storybookNotifications || [])
  const [marketList, setMarketList] = React.useState([])

  const microCopyData = React.useContext(MicroCopyContext)
  const { isTextRtl } = React.useContext(SiteDataContext)

  const {
    market: activeMarket,
    locale: activeLocale,
    allMarketsByTitle,
    allMarkets,
    allMarketsSlugs
  } = React.useContext(SiteDataContext)
  const { isoCode, flagUrl } = activeMarket || {}

  const slugToLang = {}
  const langToSlug = {}

  Object.keys(langSlugs).forEach((lang) => {
    slugToLang[lang] = getMicroCopy({ key: `footer.languages.${lang}`, data: microCopyData })
    langToSlug[slugToLang[lang]] = lang
  })

  const fetchNotifications = async (query, variables, isoCode) => {
    try {
      setIsLoading(true)

      const result = await graphqlFetch(query, variables)
      if (result.errors) {
        throw new Error(JSON.stringify(result.errors))
      }

      if (!result?.data?.notificationsCollection?.items) {
        setNotifications([])
        setIsLoading(false)
      }

      const { items } = result?.data?.notificationsCollection

      const enabledNotifications = items
        .filter((notification) => isEnabled(notification, isoCode))
        .map((notification) => ({
          ...notification,
          url: getUrl(notification, slugPrefix)
        }))

      setNotifications(enabledNotifications || [])
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const variables = {
      locale: activeLocale
    }
    if (!storybookNotifications?.length) fetchNotifications(notificationsQuery, variables, isoCode)

    // Set New Market / Language List (changes on the label)
    const locations = { ...allMarketsByTitle }
    const marketsList = []

    Object.keys(locations).forEach((location) => {
      const market = locations[location]
      const { enabledLanguages, countryIsoCode, title } = market
      market.languages = enabledLanguages.map((locale) => {
        // get enabled slugs
        const m = allMarkets[countryIsoCode]?.[locale] || null

        if (!m) {
          const marketDefaultSlugs = allMarketsSlugs.find((m) => m.title === title).slugsPair

          return {
            label: getMicroCopy({ key: `footer.languages.${locale}`, data: microCopyData }),
            slugPrefix: marketDefaultSlugs[locale],
            slug: `/${marketDefaultSlugs[locale]}`,
            locale: locale
          }
        }

        let matchMarketSlug = ''

        if (Array.isArray(allLangSlugs)) {
          matchMarketSlug =
            allLangSlugs?.find((lang) => lang.slugPrefix === m.slugPrefix)?.slug ||
            `/${m.slugPrefix}` ||
            ''
        } else {
          matchMarketSlug = `/${m.slugPrefix}` || ''
        }

        return {
          label: getMicroCopy({ key: `footer.languages.${locale}`, data: microCopyData }),
          slugPrefix: m.slugPrefix,
          locale: m.locale,
          slug: matchMarketSlug
        }
      })
      marketsList.push(market)
    })
    setMarketList(marketsList)
  }, [activeLocale, isoCode])

  const handleSelectMarket = (language, e) => {
    e.stopPropagation()
    navigate(language.slug)
  }

  return (
    <>
      <div className={cn('c-top-bar', { 'c-top-bar--rtl': isTextRtl })}>
        <div className="c-top-bar__container l-container--default-spacing">
          <div className="c-top-bar__notification-bar-container">
            {!isLoading && notifications.length ? (
              <NotificationBar notifications={notifications} />
            ) : null}
            {isLoading && <Loader />}
          </div>
          <div className="c-top-bar__actions-container">
            {headerAction && headerAction.url && headerAction.label && (
              <a href={headerAction.url} className="c-top-bar__action c-top-bar__action__item">
                <Icon
                  name={'tabler-square-arrow-right'}
                  size={24}
                  classes={'c-top-bar__action__icon'}
                />
                {headerAction.label}
              </a>
            )}
            <button
              className="c-top-bar__action__item c-top-bar__action__button"
              onClick={() => setIsOpen(!isOpen)}
            >
              {flagUrl ? (
                <Icon name={'tabler-flag'} size={24} classes={'c-top-bar__action__icon'} />
              ) : null}
              <span>{activeLocale}</span>
            </button>
          </div>
        </div>
      </div>
      <SideDrawer
        isOpen={isOpen}
        languageLocationMenu={{
          locationLabel: getMicroCopy({
            key: 'header.location',
            fallback: 'Location',
            data: microCopyData
          }),
          languageLabel: getMicroCopy({
            key: 'header.language',
            fallback: 'Select language',
            data: microCopyData
          }),
          title: getMicroCopy({
            key: 'header.chooseYourLocation',
            fallback: 'Select your location and language',
            data: microCopyData
          }),
          marketItems: marketList,
          handleSelectMarket: handleSelectMarket
        }}
        handleIsOpen={(val) => setIsOpen(val)}
      />
    </>
  )
}
