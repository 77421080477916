import cn from 'classnames'
import React from 'react'

import { IconNew } from '..'
import { SiteDataContext } from '../../components'
import { MediaComponent } from '../../components/blocks/crissCross/mediaComponent'
import RichText from '../../components/rich-text'
import { getUserAgentVariableText } from '../../helpers/getUserAgentVariableText'
import { ImageFluid } from '../../utils/image'
import { withAnchor } from '../hooks'

//
import { ALIGN, HEADING_TYPE } from './SectionHeader.const'

const LogoSectionHeader = ({ logo, alt }) => {
  if (typeof logo === 'string') {
    return <img loading="lazy" className="c-section-header__logo" src={logo} alt={alt} />
  }
  return (
    <div className="c-section-header__logo-container">
      <ImageFluid image={logo} height={64} containImages={true} />
    </div>
  )
}

const SectionHeader = ({
  title = '',
  headingType,
  icon = null,
  logo = null,
  media = null,
  eyebrow = '',
  text = null,
  textTokens = {},
  align = ALIGN.left,
  inverted = false,
  classes = '',
  children,
  greenEyebrow = false,
  variant = '',
  prefaceBelow = false,
  image
}) => {
  const { isTextRtl, market } = React.useContext(SiteDataContext)
  const HeadingTag = headingType || HEADING_TYPE.h2

  const marketTitle = market?.localName || market?.title
  const newTitle = getUserAgentVariableText(title, marketTitle)

  return (
    <div
      data-variant={variant}
      className={cn('c-section-header', classes, {
        'c-section-header--center': align === ALIGN.center,
        'c-section-header--center@to:viewport-7': media,
        'c-section-header--inverted': inverted,
        'c-section-header--left': align === ALIGN.left,
        'c-section-header--rtl': isTextRtl
      })}
    >
      {logo && <LogoSectionHeader logo={logo} alt={eyebrow || title} />}

      {icon && !logo && <IconNew classes="c-section-header__icon" name={icon} />}

      <div className={`c-section-header__heading-container${prefaceBelow ? '--inverted' : ''}`}>
        {eyebrow && (
          <p
            className={`c-section-header__eyebrow ${
              greenEyebrow ? 'c-section-header__eyebrow--green' : ''
            }`}
          >
            {eyebrow}
          </p>
        )}

        {title && <HeadingTag className="c-section-header__title">{newTitle || title}</HeadingTag>}
      </div>

      {media && <div className="c-section-header__media">{media}</div>}

      {text && (
        <div className="c-section-header__text">
          {typeof text === 'string' ? (
            <div>
              <p>{text}</p>
            </div>
          ) : (
            <RichText data={text} tokens={textTokens} />
          )}
        </div>
      )}

      {children && <div className="c-section-header__actions">{children}</div>}

      {image ? (
        <div className="c-section-header__image">
          <MediaComponent {...{ image }} />
        </div>
      ) : null}
    </div>
  )
}

export default withAnchor(SectionHeader)
